
import BillingInfo from '@/components/view/common/billing-info';
import { PropType, ref, defineComponent } from 'vue';
import { autoPayCancleReasonMap } from '@/constant/field-map';
import {
    getInfoSubscription,
    GetInfoSubscription,
    cancelSubscription,
    delSubscription,
    GetListForInsSubscription
} from '@/api/community/subscription';
import { saveTableSortStatus, getDefaultSort, setTableSortByFrontEnd } from '@/util/table';

import PanelList from '@/components/common/panel-list';
import { ElMessageBox } from 'element-plus';

export default defineComponent({
    components: {
        PanelList,
        BillingInfo
    },
    props: {
        lastStep: {
            type: String,
            default: '',
            require: true
        },
        userType: {
            type: String,
            default: '',
            require: true
        },
        isBatch: {
            type: Boolean,
            default: false,
            require: true
        },
        isBatchForAutoRenew: {
            type: String,
            require: true
        },
        data: {
            type: Object as PropType<GetListForInsSubscription['row'][0]>,
            default: () => ({}),
            require: true
        }
    },
    setup(props, { emit }) {
        function back() {
            emit('changeStep', { type: 'back', to: props.lastStep });
        }
        const autoPayInfo = ref<GetInfoSubscription>({
            ID: 0,
            OrderNumber: '',
            Status: -1,
            TotalPrice: '',
            StartTime: '',
            EndTime: '',
            NextPayTime: '',
            CreateTime: '',
            IntervalType: 0,
            Cycles: 0,
            PayPlatform: 0,
            UUID: '',
            TimeZone: '',
            Reason: -1,
            bmUrl: '',
            Items: []
        });
        const intervalTypeMap = {
            0: WordList.AutoRenewMonthly,
            1: WordList.Quarterly,
            2: WordList.Yearly
        };
        const intervalTypeMapTitle = {
            0: WordList.TotalPriceByMonth,
            1: WordList.TotalPriceByQuarter,
            2: WordList.TotalPriceByYear
        };
        const panelListColumn = [
            { prop: 'IntervalType', label: WordList.RenewType },
            // { prop: 'StartTime', label: WordList.TmpKeyManageListTanleBeginTime },
            { prop: 'Cycles', label: WordList.RenewTimes },
            { prop: 'NextPayTime', label: WordList.NextApproximatePaydate },
            { prop: 'number', label: WordList.RenewChooseAptNumber },
            { prop: 'TotalPrice', label: WordList.TotalPriceByMonth }
        ];

        const column = [
            { prop: 'UnitName', label: WordList.DeviceDetailDetailUnitName, width: '100' },
            {
                prop: 'RoomName', label: WordList.RDeviceSearchLabelRoomName, sortable: 'custom', width: '150'
            },
            {
                prop: 'RoomNumber', label: WordList.RDeviceSearchLabelRoomNumber, sortable: 'custom', width: '150'
            },
            { prop: 'Email', label: WordList.TabelPropertyManageEmail, width: '300' },
            { prop: 'Name', label: WordList.TabelPersonUserInHtmlName, width: 'auto' },
            { prop: 'ServiceType', label: WordList.ServiceType, width: '100' },
            { prop: 'Amount', label: `${WordList.ProperAllTextPackageFee}($)`, width: '120' },
            { prop: 'ExpireTime', label: WordList.TabelExpretimeBoxSpecificTime, width: 'auto' }
        ];

        // 获取订阅详情
        getInfoSubscriptionApi();
        function getInfoSubscriptionApi() {
            const params = { UUID: props.data.UUID, IsBatch: props.data.IsBatch || 0 };
            getInfoSubscription(params, (res: any) => {
                console.log('res', res);
                autoPayInfo.value = res;
            });
        }
        function toSubscriptionPage() {
            window.open(autoPayInfo.value.bmUrl);
        }
        function editAutoPay() {
            emit('changeStep', { type: 'forward', to: 'process-1', data: autoPayInfo.value });
        }
        function cancelPlan() {
            ElMessageBox.confirm(
                WordList.CancelAutomaticPaymentsTips,
                WordList.TabelDeleteTitle,
                {
                    confirmButtonText: WordList.TabelFootSure,
                    cancelButtonText: WordList.TabelFootCancel,
                    type: 'warning'
                }
            ).then(() => {
                cancelSubscription({ UUID: props.data.UUID }, () => {
                    emit('changeStep', { type: 'back', to: props.lastStep });
                }, () => { console.log('err'); });
            });
        }
        function delAutoPayRecord() {
            ElMessageBox.confirm(
                WordList.ProperAllTextDeleteTip,
                WordList.TabelDeleteTitle,
                {
                    confirmButtonText: WordList.TabelFootSure,
                    cancelButtonText: WordList.TabelFootCancel,
                    type: 'warning'
                }
            ).then(() => {
                delSubscription({ UUID: props.data.UUID }, () => {
                    emit('changeStep', { type: 'back', to: props.lastStep });
                });
            });
        }
        // 排序初始化：默认表格排序
        const tableName = 'autoPayInfoTableForCommunityIndis';
        const defaultTableSort = getDefaultSort(tableName);
        // 排序变化
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        function tableSortChange({ column, prop, order }: Record<string, string>) {
            const { SortField, Sort } = saveTableSortStatus(tableName, prop, order);
            autoPayInfo.value.Items = setTableSortByFrontEnd(autoPayInfo.value.Items, { SortField, Sort });
        }
        function generateNextPayTime() {
            if (autoPayInfo.value.NextPayTime && typeof autoPayInfo.value.NextPayTime === 'string' && autoPayInfo.value.NextPayTime.length > 0) {
                return autoPayInfo.value.NextPayTime.split(' ')[0];
            }
            return autoPayInfo.value.NextPayTime;
        }
        return {
            back,
            autoPayInfo,
            intervalTypeMap,
            intervalTypeMapTitle,
            panelListColumn,
            column,
            getInfoSubscriptionApi,
            editAutoPay,
            cancelPlan,
            delAutoPayRecord,
            defaultTableSort,
            tableSortChange,
            autoPayCancleReasonMap,
            toSubscriptionPage,
            generateNextPayTime
        };
    }
});

